
























import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export enum Variation {
  A = 'a',
  B = 'b',
  C = 'c',
}

export enum ThermometerVariation {
  Analog = 0,
  Digital = 1,
}

export const SchoolToVariation = {
  Concordia: Variation.A,
  Windsor: Variation.B,
  Upei: Variation.B,
};

export interface CalorimetryBInput {
  cCalc: number;
  Tbm: number;
}

export interface CalorimetryCInput extends CalorimetryBInput {
  cMetal: number;
}

export default defineComponent({
  name: 'LabCalorimetry',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    variation: {
      type: String as PropType<Variation>,
      default: Variation.A,
      validator: (value: string) => Object.values(Variation).includes(value as Variation),
    },
    thermometerVariation: {
      type: Number as PropType<ThermometerVariation>,
      default: ThermometerVariation.Analog,
      validator: (value: number) => Object.values(ThermometerVariation).includes(value),
    },
    cCal: {
      type: Number as PropType<number>,
      default: 16,
    },
    cMetal: {
      type: Number as PropType<number>,
      default: 1.0,
    },
    tempBeforeMixing: {
      type: Number as PropType<number>,
      default: 25,
    },
  },
  data() {
    return {
      Variation,
    };
  },
  computed: {
    calorimetryBData(): CalorimetryBInput {
      return {
        cCalc: this.cCal,
        Tbm: this.tempBeforeMixing,
      };
    },
    calorimetryCData(): CalorimetryCInput {
      return {
        ...this.calorimetryBData,
        cMetal: this.cMetal,
      };
    },
    stringifiedLabData(): string {
      return JSON.stringify(
        this.variation !== Variation.C ? this.calorimetryBData : this.calorimetryCData
      );
    },
    scriptSrc(): string {
      return `/simulations/calorimetry/${this.variation}/stemble-calorimetry-${this.variation}.js`;
    },
    stylesSrc(): string {
      return `/simulations/calorimetry/${this.variation}/styles.css`;
    },
  },
});
