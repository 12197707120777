<template>
  <lab-task
    ><lab-calorimetry :variation="variation" :c-cal="cCal" :temp-before-mixing="tempBeforeMixing" />
  </lab-task>
</template>

<script>
import Vue from 'vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {
  SchoolToVariation,
  default as LabCalorimetry,
} from '@/tasks/components/simulations/LabCalorimetry';
import LabTask from './labs/LabTask';

export default Vue.extend({
  name: 'ConcordiaCalorimetry',
  components: {LabCalorimetry, LabTask},
  mixins: [DynamicQuestionMixin()],
  data: () => ({
    variation: SchoolToVariation.Concordia,
  }),
  computed: {
    cCal() {
      return this.taskState.getValueBySymbol('cCal').content.toFloat();
    },
    tempBeforeMixing() {
      return this.taskState.getValueBySymbol('Tbm').content.toFloat();
    },
  },
});
</script>
